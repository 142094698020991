<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Крипто активы</h5>
    <DataTable
      :value="chains"
      dataKey="id"
      v-model:wallets="chains"
      responsiveLayout="scroll"
    >
      <Column header="ID цепочки" field="chainId" />
      <Column header="Имя сети" field="name" />
      <Column header="URL-адрес сети" field="entryPointsHttp" />
      <Column header="Символ валюты" field="symbol" />
      <Column class="bg-white border-none flex flex-nowrap justify-content-end">
        <template #body="slot">
          <div data-help="MetaMask">
            <div
              v-if="slot.data.entryPointsHttp"
              @click="metamaskAddEthereumChain(slot.data)"
              class="metamask m-1"
            ></div>
          </div>
          <div>
            <Button
              v-if="!slot.data.entryPointsHttp"
              disabled="disabled"
              icon="pi pi-times"
              class="p-button-rounded p-button-secondary m-1"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import MetaMask from '@/mixins/MetaMask'

export default {
  name: 'Chains',
  data() {
    return {
      chains: [],
    }
  },
  mixins: [MetaMask],
  mounted() {
    this.getBlockChains()
  },
  methods: {
    async getBlockChains() {
      try {
        const resp = await this.axios.get('api/block_chains')
        this.chains = resp.data
      } catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in block_chains api call',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: -10px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
.metamask {
  width: 3rem;
  height: 3rem;
  background: url(../../assets/img/metamask.png);
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgb(245, 159, 10, 0.4);
  padding: 20px;
  cursor: pointer;
  box-shadow: 9px 9px 14px -8px rgba(245, 159, 10, 0.2) inset;
  transition: 0.5s;
}
.metamask:hover {
  border: 1px solid rgb(245, 159, 10, 0.8);
  box-shadow: 9px 9px 14px -8px rgba(245, 159, 10, 0.6) inset;
  transition: 0.5s;
}
</style>
